import React, {Component} from 'react'
import Portal from '../Portal';
export default class MobilePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      showPopupOnMobile: false,
    }
  }
  throttledHandleWindowResize = () => {
      if (typeof window !== 'undefined') {
          this.setState({isMobile: window.innerWidth < 991});
      }
  };

  componentDidMount() {
      if (typeof window !== 'undefined') {
          window.addEventListener('resize', this.throttledHandleWindowResize);
          this.setState({ isMobile: window.innerWidth < 991 });
      }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.throttledHandleWindowResize);
    }
  }

  render() {

    const {children, open, close, title, isMobile} = this.props;

    return (
        <Portal>
            <div className="mobile-modal" id={(open) ? (isMobile) ? "show" : "" : ""}>
                <header className={"mobile-modal__header"}>
                    <span>{(title) ? title : ""}</span>
                    <a href={"#"} className={'mobile-modal__close-btn'} onClick={close}/>
                </header>
                <div className="mobile-modal-wrapper">
                    {children}
                </div>
            </div>
        </Portal>
    )
  }
}